import { CurrencyCode } from "@outschool/gql-backend-generated";
import { useLookupIP } from "@outschool/iplookup-client";
import { useLocalStorageState } from "@outschool/local-storage";
import {
  COUNTRIES_INFO,
  CountryCode,
  CurrencyLocalizationContext,
  I18nLocale,
  LocaleProvider,
  SELECTED_CURRENCY_CODE_LOCAL_STORAGE_KEY,
  SetCurrencyLocalizationContext,
  getCountryFromCurrency,
  getCurrencyFromCountryIsoCode,
  getSuggestedCurrencyCodeForLocale,
  useLocale,
} from "@outschool/localization";
import React from "react";

const CurrencyProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const locale = useLocale();
  const [selectedCurrencyLocalStorage, setSelectedCurrencyLocalStorage] =
    useLocalStorageState<CurrencyCode | null>(
      SELECTED_CURRENCY_CODE_LOCAL_STORAGE_KEY,
      getSuggestedCurrencyCodeForLocale(locale)
    );

  const { ipInfo, isInEurozone } = useLookupIP();
  const ipIsoCode: CountryCode | null = ipInfo?.country?.isoCode
    ? (CountryCode[
        (ipInfo.country.isoCode || "US") as CountryCode
      ] as CountryCode)
    : null;

  const setCurrency = React.useCallback(
    async (newCurrencyCode: CurrencyCode) => {
      try {
        setSelectedCurrencyLocalStorage(newCurrencyCode);
      } catch (err) {
        console.error(err);
      }
    },
    [setSelectedCurrencyLocalStorage]
  );

  // Get user's country from the currency set in local storage,
  // default_currency, IP's country, or default to US.
  const countryCode: CountryCode =
    getCountryFromCurrency(selectedCurrencyLocalStorage) ||
    CountryCode[ipIsoCode as CountryCode] ||
    CountryCode.US;
  const selectedCountryInfo = COUNTRIES_INFO[countryCode];
  const currencyCode = getCurrencyFromCountryIsoCode(countryCode, isInEurozone);

  React.useEffect(() => {
    // Keep selectedCurrencyLocalStorage up to date.
    if (selectedCurrencyLocalStorage !== currencyCode) {
      setSelectedCurrencyLocalStorage(currencyCode);
    }
  }, [
    locale,
    selectedCurrencyLocalStorage,
    currencyCode,
    setSelectedCurrencyLocalStorage,
  ]);

  return (
    <SetCurrencyLocalizationContext.Provider value={{ setCurrency }}>
      <CurrencyLocalizationContext.Provider
        value={{
          currencyCode,
          countryCode,
          selectedCurrencyCountry: selectedCountryInfo,
          isLoading: false,
          hasLoaded: true,
        }}
      >
        {children}
      </CurrencyLocalizationContext.Provider>
    </SetCurrencyLocalizationContext.Provider>
  );
};

export const LocalizationProvider = React.memo(
  ({
    children,
    userSelectedLocale,
  }: React.PropsWithChildren<{ userSelectedLocale?: I18nLocale }>) => (
    <LocaleProvider userSelectedLocale={userSelectedLocale}>
      <CurrencyProvider>{children}</CurrencyProvider>
    </LocaleProvider>
  )
);
