//Somewhat copied from Nextjs
export function pathHasPrefix(path: string, prefix: string) {
  if (typeof path !== "string") {
    return false;
  }

  return path === prefix || path.startsWith(prefix + "/");
}

//Prepends /ko to a link if needed. Pass in locale+defaultLocale from useRouter
export function localizeTo(to: string, locale: string, defaultLocale: string) {
  // ignore fully qualified URLs
  if (typeof to !== "string" || /^https?/.test(to)) {
    return to;
  }
  const shouldAddLocale =
    locale !== defaultLocale &&
    !pathHasPrefix(to.toLowerCase(), `/${locale.toLowerCase()}`);
  return shouldAddLocale ? `/${locale}${to}` : to;
}
